




































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  CmsCategoryCreateOrUpdateDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage.vue";
import Ueditor from "@/components/Ueditor.vue";

@Component({
  components: { SimpleUploadImage, Ueditor },
})
export default class CreateCmsCategory extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: CmsCategoryCreateOrUpdateDto = {
    id: 0,
    displayName: undefined,
    displayNameFr: undefined,
    categoryCode: undefined,
    description: undefined,
    isShow: undefined,
    parentId: undefined,
    isNavigation: true,
    titleImagePath: undefined,
  };
  isConfirm = false;

  get hostType() {
    return AttachmentHostType.CmsCategory;
  }

  created() {
    if (this.$route.params.parentId) {
      this.form.parentId = Number(this.$route.params.parentId);
    }
    if (this.$route.params.id) {
      api.cmsCategory.get({ id: Number(this.$route.params.id) }).then((res) => {
        this.form = { ...res };
      });
    }
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form?.id) {
          await api.cmsCategory.update({
            body: this.form,
          });
        } else {
          if (this.form.parentId === 0) {
            this.form.parentId = undefined;
          }
          await api.cmsCategory.create({
            body: this.form,
          });
        }
        this.$router.back();
        this.$message.success(
          (this as any).$l.getLanguageText("basic.updateSuccess").toString()
        );
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  get pageHeadTitle() {
    if ((this as any).$route.params.id) {
      return (this as any).$l.getLanguageText("cmsNavigation.editNavigation");
    } else {
      return (this as any).$l.getLanguageText("cmsNavigation.createNavigation");
    }
  }

  roleRule = {
    displayName: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "cmsNavigation.tips.displayName"
        ),
        trigger: "blur",
      },
    ],
    displayNameFr: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "cmsNavigation.tips.displayNameFr"
        ),
        trigger: "blur",
      },
    ],
    categoryCode: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "cmsNavigation.enterNavigationCode"
        ),
        trigger: "blur",
      },
    ],
  };
}
